var db = {
  save: function save(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  },
  get: function get(key) {
    var defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    // return JSON.parse(localStorage.getItem(key)) || defaultValue
    return localStorage.getItem(key) && localStorage.getItem(key) !== 'undefined' && localStorage.getItem(key) !== 'null' ? JSON.parse(localStorage.getItem(key)) : defaultValue;
  },
  remove: function remove(key) {
    localStorage.removeItem(key);
  },
  clear: function clear() {
    localStorage.clear();
  }
};
export default db;