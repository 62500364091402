var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header-search", class: { show: _vm.show } },
    [
      _c("svg-icon", {
        staticStyle: { "margin-top": "3px" },
        attrs: {
          "class-name": "search-icon",
          "icon-class": "magnifying-glass",
        },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.click($event)
          },
        },
      }),
      _vm._v(" "),
      _c(
        "el-select",
        {
          ref: "headerSearchSelect",
          staticClass: "header-search-select",
          attrs: {
            "remote-method": _vm.querySearch,
            filterable: "",
            "default-first-option": "",
            remote: "",
            placeholder: _vm.$t("table.search"),
          },
          on: { change: _vm.change },
          model: {
            value: _vm.search,
            callback: function ($$v) {
              _vm.search = $$v
            },
            expression: "search",
          },
        },
        _vm._l(_vm.options, function (item) {
          return _c("el-option", {
            key: item.path,
            attrs: { value: item, label: item.title.join(" > ") },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }