var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("Navbar", { staticClass: "sidebar-container" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.device !== "mobile"
            ? [
                _c("search", {
                  staticClass: "right-menu-item",
                  attrs: { id: "header-search" },
                }),
                _vm._v(" "),
                _c("screenfull", {
                  staticClass: "right-menu-item hover-effect",
                  attrs: { id: "screenfull" },
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "header-right" },
            [
              _c(
                "el-dropdown",
                {
                  attrs: {
                    type: "split-button",
                    trigger: "click",
                    placement: "bottom",
                    "hide-on-click": true,
                  },
                },
                [
                  _c(
                    "el-badge",
                    {
                      staticClass: "item",
                      attrs: {
                        hidden: _vm.messageNum == 0,
                        value: _vm.messageNum,
                        max: 99,
                      },
                    },
                    [_c("i", { staticClass: "el-icon-bell message-icons" })]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-menu",
                    {
                      ref: "messageDrop",
                      attrs: { slot: "dropdown" },
                      slot: "dropdown",
                    },
                    [
                      _c("div", { staticClass: "message-head" }, [
                        _c("span", [
                          _vm._v(
                            "\n                Message Notification\n              "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.messageList, function (item) {
                        return _c(
                          "el-dropdown-item",
                          { key: item.id, attrs: { divided: "" } },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "message-box",
                                on: {
                                  click: function ($event) {
                                    return _vm.updateMessage(item)
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "center" }, [
                                  _c("p", { staticClass: "title" }, [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(item.title) +
                                        "\n                  "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", [_vm._v(_vm._s(item.createTime))]),
                                ]),
                              ]
                            ),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-item",
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "message-btn",
                              staticStyle: { "padding-left": "0px" },
                              attrs: { type: "text" },
                              on: { click: _vm.enter },
                            },
                            [_vm._v("View More")]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "click" },
            },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("img", {
                  staticClass: "user-avatar",
                  attrs: { src: _vm.avatar, alt: "avatar" },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "user-name" }, [
                  _vm._v(_vm._s(_vm.username)),
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "user-name",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.deleteCache($event)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("navbar.deleteCache")))]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "user-name",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.logout($event)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("navbar.logOut")))]
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c("el-dropdown-item", [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.setting },
                      },
                      [_vm._v(_vm._s(_vm.$t("navbar.setting")))]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _vm.userViewVisible
        ? _c("user-view", {
            ref: "view",
            attrs: {
              "dialog-visible": _vm.userViewVisible,
              "basic-info": _vm.detailInfo,
            },
            on: { viewClose: _vm.viewClose },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }