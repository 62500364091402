import _objectSpread from "/Users/admin/IdeaProjects/credit-ms-web/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import Vue from 'vue';
import VueI18n from 'vue-i18n';
// import db from '@/utils/localstorage'
import elementEnLocale from 'element-ui/lib/locale/lang/en'; // element-ui lang
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'; // element-ui lang
import enLocale from "./en";
import zhLocale from "./zh";
Vue.use(VueI18n);
var messages = {
  en: _objectSpread(_objectSpread({}, enLocale), elementEnLocale),
  zh: _objectSpread(_objectSpread({}, zhLocale), elementZhLocale)
};
export function getLanguage() {
  // const chooseLanguage = db.get('LANGUAGE', '')
  // if (chooseLanguage) return chooseLanguage
  //
  // // if has not choose language
  // const language = (navigator.language || navigator.browserLanguage).toLowerCase()
  // const locales = Object.keys(messages)
  // for (const locale of locales) {
  //   if (language.indexOf(locale) > -1) {
  //     return locale
  //   }
  // }
  return 'en';
}
var i18n = new VueI18n({
  // set locale
  // options: en | zh | es
  locale: getLanguage(),
  // set locale messages
  messages: messages,
  silentTranslationWarn: true
});
export default i18n;