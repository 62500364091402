var getters = {
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  asyncRouterArr: function asyncRouterArr(state) {
    return state.account.routes;
  }
};
export default getters;