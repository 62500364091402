//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import service from "@/utils/request2";
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    basicInfo: {
      type: Object
    }
  },
  data: function data() {
    return {
      localDialogVisible: this.dialogVisible
    };
  },
  created: function created() {
    if (!this.basicInfo.userRead) {
      //  console.log(this.basicInfo.userRead,'this.basicInfo.userRead')
      this.settingRead();
    }
  },
  methods: {
    settingRead: function settingRead() {
      var _this = this;
      service.get("system/sys-msg/read/".concat(this.basicInfo.id)).then(function () {
        _this.$message({
          message: 'Success',
          type: 'success'
        });
      });
    },
    viewClose: function viewClose() {
      this.$emit('viewClose');
    }
  }
};