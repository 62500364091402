var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Detail",
            width: "60%",
            top: "20px",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            visible: _vm.localDialogVisible,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.localDialogVisible = $event
            },
            close: _vm.viewClose,
          },
        },
        [
          _c("div", { staticClass: "stepStyle" }, [
            _c("div", [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "flex-direction": "row",
                    "flex-wrap": "wrap",
                    width: "100%",
                  },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { width: "33%", margin: "10px 0" } },
                    [
                      _c("span", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v("Title:"),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticStyle: { "margin-left": "10px" } }, [
                        _vm._v(_vm._s(_vm.basicInfo.title)),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { width: "33%", margin: "10px 0" } },
                    [
                      _c("span", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v("Type:"),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticStyle: { "margin-left": "10px" } }, [
                        _vm._v(_vm._s(_vm.basicInfo.msgType)),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { width: "33%", margin: "10px 0" } },
                    [
                      _c("span", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v("CreateTime:"),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticStyle: { "margin-left": "10px" } }, [
                        _vm._v(_vm._s(_vm.basicInfo.createTime)),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("p", { staticStyle: { display: "flex" } }, [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "font-weight": "bold",
                      "min-width": "100px",
                    },
                  },
                  [_vm._v("Content:")]
                ),
                _vm._v(" "),
                _c("span", {
                  staticClass: "ifImg",
                  domProps: { innerHTML: _vm._s(_vm.basicInfo.content) },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { "margin-top": "30px" } },
              [
                _c(
                  "el-table",
                  { attrs: { border: "", data: _vm.basicInfo.recipients } },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "realName", label: "realName" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "userRead", label: "UserRead" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c(
                                "span",
                                { class: { colorRed: row.userRead == false } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      row.userRead == false ? "NoRead" : "Read"
                                    )
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }