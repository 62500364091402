//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import RightPanel from '@/components/RightPanel';
import { AppMain, Navbar, Settings, Sidebar, TagsView } from "./components";
import ResizeMixin from "./mixin/ResizeHandler";
export default {
  name: 'Layout',
  components: {
    AppMain: AppMain,
    Navbar: Navbar,
    RightPanel: RightPanel,
    Settings: Settings,
    Sidebar: Sidebar,
    TagsView: TagsView
  },
  mixins: [ResizeMixin],
  computed: {
    sidebar: function sidebar() {
      return this.$store.state.setting.sidebar;
    },
    device: function device() {
      return this.$store.state.setting.device;
    },
    showSettings: function showSettings() {
      return this.$store.state.setting.settingBar.opened;
    },
    needTagsView: function needTagsView() {
      // 0831
      // return this.$store.state.setting.multipage
      return true;
    },
    fixedHeader: function fixedHeader() {
      return this.$store.state.setting.fixHeader;
    },
    classObj: function classObj() {
      return {
        // 0831hideSidebar: !this.sidebar.opened,
        hideSidebar: false,
        // 0831 openSidebar: this.sidebar.opened,
        openSidebar: true,
        // 0831 withoutAnimation:this.sidebar.withoutAnimation
        withoutAnimation: false,
        // 0831mobile: this.device === 'mobile'
        mobile: false
      };
    }
  },
  methods: {
    handleClickOutside: function handleClickOutside() {
      this.$store.commit('setting/closeSidebar', {
        withoutAnimation: false
      });
    }
  }
};